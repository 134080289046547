import { reservation_init, get_chekout, get_reservation_admin } from "@/services/models/reservation";

const reservation = {
  namespaced: true,
  state: {
    reservationDate: null,
    reservation: null,
    reservationAdmin: null,
  },

  getters: {
    reservationDate: (state) => state.reservationDate,
    reservation: (state) => state.reservation,
    reservationAdmin: (state) => state.reservationAdmin,
  },

  mutations: {
    SET_DATE(state, payload) {
      state.reservationDate = payload;
    },
    SET_RESERVATION(state, payload) {
      state.reservation = payload;
    },
    SET_RESERVATION_ADMIN(state, payload) {
      state.reservationAdmin = payload;
      return
    }
  },

  actions: {
    async getReservationAdmin({ commit }, data) {
      try {
        const response = await get_reservation_admin(data);
        commit("SET_RESERVATION_ADMIN", response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async reservationDates({ commit }, data) {
      commit("SET_DATE", data);
    },

    async reservationData({ commit }, data) {
      try {
        const reservatioInit = await reservation_init(data);
        const get_checkout = await get_chekout();
        if(get_checkout.sucess == true){
          commit("SET_RESERVATION", get_checkout);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async checkout({ commit }, data) {
      try {
        const get_checkout = await get_chekout(data);
        if(get_checkout.sucess == true){
          commit("SET_RESERVATION", get_checkout);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};

export default reservation;
