export default async function (to, from, next) {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    try {
      if (userData && userData.auth) {
        next({
          query: { redirect: to.fullPath },
        });
      } else {
        next({
          path: "/login",
          query: { redirect: to.fullPath },
        });
      }
    } catch (error) {
      console.error("Erro ao verificar autenticação:", error);
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
}
