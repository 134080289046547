import axios from "axios";

const hostname = window.location.hostname;
let url = "";

if (hostname === "localhost") {
  url = "http://localhost:3023/api";
} else if (hostname === "dev.cartya.com.br") {
  url = "https://dev-api.cartya.com.br/api";
} else {
  url = "https://api.cartya.com.br/api";
}

const api = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  },
});

export { api };
