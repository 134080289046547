import { getProfile } from "@/services/models/userDatas"

const user = {
    namespaced: true,
    state: {
      profile: null,
    },

    getters: {
        profile: (state) => state.profile,
    },

    mutations: {
      SET_PROFILE(state, payload) {
        state.profile = payload
      },
    },

    actions: {
      async fetchAuth({ commit }, data) {
        const res = await getProfile()
        localStorage.removeItem("userData");
        localStorage.setItem("userData", JSON.stringify(res));
        commit('SET_PROFILE', res)
      },
      setProfile({ commit }, data) {
        commit('SET_PROFILE', data)
      },
    }
  }
  
  export default user