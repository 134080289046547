export default {
  path: "/",
  redirect: "/",
  name: "Home",
  component: () => import("@/views/HomeView.vue"),
  children: [
    {
      path: "",
      name: "Rentdaly",
      component: () => import("@/components/Reservation/Reservation.vue"),
    },
    {
      path: "rentmonthly",
      name: "Rentmonthly",
   
    },
    {
      path: "rentsubscription",
      name: "RentSubscription",

    },
    {
      path: "/partner_registration",
      name: "PartnerRegistration",

    },{
      path: "/checkout",
      name: "Checkout",
      component: () => import("@/components/Checkout/Checkout.vue"),
    },{
      path: "/reservation_success",
      name: "ReservationSuccess",
      component: () => import("@/components/ReservationSuccess/ReservationSuccess.vue"),
    },
  ],
}
