import axios from "axios";

const apiCEP = axios.create({
  baseURL: "https://viacep.com.br/ws/",
});

const estados = {
  "AC": "Acre",
  "AL": "Alagoas",
  "AP": "Amapá",
  "AM": "Amazonas",
  "BA": "Bahia",
  "CE": "Ceará",
  "DF": "Distrito Federal",
  "ES": "Espírito Santo",
  "GO": "Goiás",
  "MA": "Maranhão",
  "MT": "Mato Grosso",
  "MS": "Mato Grosso do Sul",
  "MG": "Minas Gerais",
  "PA": "Pará",
  "PB": "Paraíba",
  "PR": "Paraná",
  "PE": "Pernambuco",
  "PI": "Piauí",
  "RJ": "Rio de Janeiro",
  "RN": "Rio Grande do Norte",
  "RS": "Rio Grande do Sul",
  "RO": "Rondônia",
  "RR": "Roraima",
  "SC": "Santa Catarina",
  "SP": "São Paulo",
  "SE": "Sergipe",
  "TO": "Tocantins"
};

function add_uf_name(obj) {
  if (obj.uf && estados[obj.uf]) {
    obj.uf_name = estados[obj.uf];
  }
  return obj;
}

const getCEP = async (cep) => {
    try {
      const response = await apiCEP.get(`${cep}/json/`);
      const address = add_uf_name(response.data);
      return address
    } catch (error) {
      throw error;
    }
  };

export { getCEP };

