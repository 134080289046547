//import api from "@/services/api";

export default {
  name: "RegisterCartyaBank",

  data: () => ({
    alertMessage: "",
    shakeAnimated: false,
    bank_signup: {
      name: null,
      email: null,
      phone: null,
    },
    rulesEmail: [
      (value) => !!value || "Campo obrigatório",
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email inválido";
      },
    ],
    rules: [(value) => !!value || "Campo obrigatório"],
  }),
  methods: {
    async bankSubmitSignUp() {
      if (
        !this.bank_signup.name ||
        !this.bank_signup.email ||
        !this.bank_signup.phone
      ) {
        this.alertMessage = "Por favor, preencha todos campos!";
        this.shakeAnimated = true;
        setTimeout(() => {
          this.alertMessage = "";
          this.shakeAnimated = false;
        }, 2000);
      } else {
        try {
          api
            .post("/newsletter", {
              name: this.bank_signup.name,
              phone: this.bank_signup.phone,
              email: this.bank_signup.email,
            })
            .then((response) => {
              if (response.status === 200 && response.data.success) {
                this.alertMessage = "Pré-cadastro enviado com sucesso!";
                // this.snackbar.text = "Pré-cadastro enviado com sucesso!";
                // this.snackbar.show = true;
              } else {
                // this.snackbar.text = response.data.err;
                // this.snackbar.show = true;
              }
            });
        } catch (error) {
          console.error(error.message);
          // this.snackbar.text = e.response.data.err || "Usuário cadastrado.";
          // this.snackbar.show = true;
        } finally {
        }
      }
    },
  },
};
