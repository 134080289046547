import Vue from "vue";
import Meta from 'vue-meta';
import App from "./App.vue";
import router from "./router/routes.js";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMask from 'v-mask';
import VTextFieldFormat from "@/components/TextFieldFormat.vue";
import VTextFieldCep from "@/components/CustomComponents/v-text-field-cep";

Vue.use(Meta);
Vue.component('v-text-field-format', VTextFieldFormat);
Vue.component('v-text-field-cep', VTextFieldCep);

Vue.use(VueMask);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
