import logocartyaimg from "@/assets/logo-cartya.svg";
import { logout } from "@/services/models/auth";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Toolbar",

  data: () => ({
    logoCartya: logocartyaimg,
    user: null,
    drawer: false,
  }),

  computed: {
    ...mapGetters("user", ["profile"]),
  },
  
  methods: {
    ...mapActions("user", ["setProfile", "fetchAuth"]),
    async logout() {
      localStorage.removeItem("userData");
      await logout();
      if (this.$route.path !== "/rentdaily") {
        this.$router.push({name: "Home"})
      };
      location.reload()
    },

    login() {
      if (this.$route.path !== "/login") {
        this.$router.push("/login");
      }
    },
  },

  created() {
    this.fetchAuth()
    /* this.user = JSON.parse(localStorage.getItem("userData"));
    this.setProfile(this.user) */
  },
};
