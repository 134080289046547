<template>
  <v-dialog v-model="loadingLinear" persistent width="400" height="200" >
    <v-card class=" justify-center align-center elevation-0 pa-10 rounded-xl">
      <p class="text-center"> Carregando... <br/> Aguarde um momento!</p>
      <v-progress-linear width="150" size="10" color="greenCartya" indeterminate></v-progress-linear>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LoadingLinear",

  props: {
    loadingLinear: Boolean,
  },
};
</script>