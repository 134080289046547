<template>
  <v-app>
    <HeaderHome class="noPrint"/>
    <router-view />
    <PartnerRegistration v-if="$route.path == '/partner_registration'" />
    <Footer class="noPrint"/>
  </v-app>
</template>

<script>
import HeaderHome from "@/components/HeaderHome/HeaderHome.vue";
// import TabsHome from "@/components/TabsHome/TabsHome.vue";
import Footer from "@/components/Footer/Footer.vue";
import Reservation from "@/components/Reservation/Reservation.vue";
import AdsDriver from "@/components/AdsDriver/AdsDriver.vue";
import AdsCartyaBank from "@/components/AdsCartyaBank/AdsCartyaBank.vue";
import RegisterCartyaBank from "@/components/RegisterCartyaBank/RegisterCartyaBank.vue";
import PartnerRegistration from "@/components/PartnerRegistration/PartnerRegistration.vue";

export default {
  name: "App",

  components: {
    HeaderHome,
    // TabsHome,
    Reservation,
    AdsDriver,
    AdsCartyaBank,
    RegisterCartyaBank,
    PartnerRegistration,
    Footer,
  },
};
</script>
