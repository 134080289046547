<template>
  <v-text-field
    v-model.lazy="v"
    v-bind="$attrs"
    @blur="onBlur"
    @focus="onFocus"
    class="text-right"
  >
  </v-text-field>
</template>

<style>
.text-right input {
  text-align: right;
}
</style>

<script>
import moment from "moment";
export default {
  name: "VTextFieldFormat",
  props: {
    format: {
      type: String,
      default: "S",
    },
  },
  data() {
    return {
      v: this.$attrs.value,
    };
  },
  watch: {
    "$attrs.value"() {
      this.formatter();
    },
  },
  methods: {
    formatter() {
      if (
        this.$attrs.value === undefined ||
        this.$attrs.value === null ||
        this.$attrs.value === ""
      ) {
        this.v = null;
        return;
      }

      switch (this.format) {
        case "N": // Number no decimal
          this.$attrs.value = +this.$attrs.value
            .toString()
            .replace(/[^\d-.]/g, "");
          this.v = this.$attrs.value.toLocaleString("pt-BR", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
          break;

        case "F": // Float 4 decimals
          this.$attrs.value = +this.$attrs.value
            .toString()
            .replace(/[^\d-.]/g, "");
          this.v = this.$attrs.value.toLocaleString("pt-BR", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          });
          break;

        case "D": // Date
          var value = this.$attrs.value;
          
          var m = moment(value);

          if (m.isValid()) {
            this.v = m.format("DD/MM/YYYY");
            this.$attrs.value = m.format("DD/MM/YYYY"); // the format that goes to the database is different from the one goes to text
          } else {
            this.v = null;
            this.$attrs.value = null;
          }

          break;

        case "H": // Date
          var value = this.$attrs.value;
          var m = moment(value, "HH:mm:ss");

          if (m.isValid()) {
            this.v = m.startOf("hour").format("HH:mm");
            this.$attrs.value = m.startOf("hour").format("HH:mm"); // the format that goes to the database is different from the one goes to text
          } else {
            this.v = null;
            this.$attrs.value = null;
          }

          break;

        case "M": // Money 2 decimals no dollar sign
          this.$attrs.value = +this.$attrs.value
            .toString()
            .replace(/[^\d-.]/g, "");
          this.v = this.$attrs.value.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          break;

        case "P": // Percentage 2 decimals and save de decimal value
          this.$attrs.value = +this.$attrs.value
            .toString()
            .replace(/[^\d-.]/g, "");

          // Divida o valor por 100 se necessário
          if (typeof this.$attrs.value === "number") {
            this.$attrs.value /= 100;
          }

          this.v = this.$attrs.value.toLocaleString("pt-BR", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          break;
      }
    },

    onFocus() {
      if (this.$children && this.$children.length > 0) {
        var i = this.$children[0].$refs.input;
        if (i.createTextRange) {
          var r = i.createTextRange();
          r.move("character", 0);
          r.select();
        } else {
          i.setSelectionRange(0, i.value.length);
        }
      }
      this.v = this.$attrs.value;
    },

    onBlur() {
      if (!this.v || this.v.length === 0) {
        this.$attrs.value = null;
      } else {
        this.$attrs.value = this.v;
      }

      this.formatter();

      this.$emit("input", this.$attrs.value);
    },
  },
  created() {
    this.formatter();
    this.$emit("input", this.$attrs.value);
  },
};
</script>
