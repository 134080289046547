import Vue from "vue";
import Vuex from "vuex";
import navigation from "./modules/navigation.module.js";
import user from "./modules/user.module.js";
import rentalCompany from "./modules/rentalCompany.module.js";
import  reservation from "./modules/reservation.module.js";
import fixed from "./modules/fixed.module.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    navigation,
    rentalCompany,
    reservation,
    fixed
  },
});

export default store;