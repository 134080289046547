
const rentalCompany = {
  namespaced: true,
  state: {
    rentalCompany: null,
  },

  getters: {
    rentalCompany: (state) => state.rentalCompany,
  },

  mutations: {
    SET_DATA_RENTAL(state, payload) {
      state.rentalCompany = payload;
    },
  },

  actions: {
    async setRentalCompany({ commit }, data) {
      commit("SET_DATA_RENTAL", data);
    },
  },
};

export default rentalCompany;
