import {api} from '../api';

const sendPartner = async (payload) => {
  try {
    const response = await api.post("/candidate", payload);
    return response
  } catch (error) {
    console.error(error)
  }
}

export {
    sendPartner
  }