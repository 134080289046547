import ModalReturn from "@/components/Modais/ModalReturn/ModalReturn.vue";
import velocimetro from "@/assets/velocimetro.png";
import { sendPartner } from "@/services/models/partner";

export default {
  name: "PartnerRegistration",

  components: {
    ModalReturn
  },

  data: () => ({
    velocimetro: velocimetro,
    ModalReturn: {}, 
    dialog: false,
    post: {
      name: null,
      contact: null,
      phone: null,
      email: null,
    },
    phoneMask: "(##) ####-####",
    nameRules: [(v) => !!v || "Nome da locadora obrigatório"],

    contactRules: [(v) => !!v || "nome do responsável obrigatório"],

    phoneRules: [(v) => !!v || "Telefone obrigatório"],

    emailRules: [
      (v) => !!v || "Email obrigatório",
      (v) =>
        /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Email deve ser válido",
    ],
  }),

  //TODO: Criar uma unica funcao como filtro para dodos os componentes
  watch: {
    "post.phone"(newVal) {
      if (newVal.replace(/\D/g, "").length == 11) {
        this.phoneMask = "(##) #####-####";
      } else if (newVal.replace(/\D/g, "").length == 10) {
        this.phoneMask = "(##) ####-####";
      }
    },
  },

  methods: {
    async registerCandidate() {
      try {
        if (await this.validateForm()) {
          this.isloading = true;
          const response = await sendPartner(this.post);
          if (response.status === 200 && response.data.success) {
            this.dialog = true;
            this.$refs.form.reset();
          } else {
            this.ModalReturn = {
              showModal: true,
              status: "error",
              title: response.data.err,
            }
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isloading = false;
      }
    },

    async validateForm() {
      try {
        if (await this.$refs.form.validate()) {
          return true;
        } else {
          this.ModalReturn = {
            showModal: true,
            status: "error",
            title: "Confira os dados e tente novamente",
          }
          return false;
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
