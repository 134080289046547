export default {
  name: "ModalReturn",

  data: () => ({
    status: "",
    icon: "mdi-information-variant-circle",
    success: false,
    error: false,
  }),

  props: {
    ModalReturn: Object,
  },

  watch: {
    ModalReturn(value) {
      if (value.showModal) {
        if (value.status == "success") {
          this.success = true;
          this.status = "greenCartya";
        } else if (value.status == "error") {
          this.error = true;
          this.status = "error";
        }
        this.closeDialog();
      }
    },
  },

  methods: {
    closeDialog() {
      setTimeout(() => {
        this.ModalReturn.showModal = false;
        this.success = false;
        this.error = false;
      }, 3000);
    },
  },
};
